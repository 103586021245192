//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from "../headers/MainHeader"
import {mapGetters, mapState} from "vuex";

export default {
  name: "TheCourse",
  metaInfo () {
    return {
      title: this.title,
    }
  },
  data() {
    return {
      title: this.$i18n.t('profile.title')
    }
  },
  computed: {
    ...mapState([
        'currentStudent'
    ]),
    ...mapGetters([
      'isContent',
    ])
  },
  components: { MainHeader}
}
